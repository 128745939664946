.ant-picker {
  width: 100%;
  border-radius: 0;

  input {
    padding: 0;
  }

  &-footer {
    display: none;
  }

  &-suffix {
    height: 24px;
  }
}

.ant-picker-dropdown {
  z-index: 10001 !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.18);
  border-radius: 16px;
  .ant-picker-panel-container {
    border-radius: 16px;
  }
  .ant-picker-panel {
    border-radius: 16px;
    width: 358px;
    .ant-picker-date-panel {
      width: 358px;
    }
  }
  .ant-picker-header {
    padding: 0 16px;
    height: 60px;
    &-super-prev-btn,
    &-super-next-btn {
      display: none;
    }
    &-view {
      font-size: $medium2;
      font-weight: $semi-bold;
      display: flex;
      line-height: 28px;
    }
    &-prev-btn {
      position: absolute;
      right: 56px;
      height: 60px;
      width: 24px;
      .ant-picker-prev-icon::before {
        width: 10px;
        height: 10px;
        border: 0 solid $default;
        border-width: 1.5px 0 0 1.5px;
      }
    }
    &-next-btn {
      height: 60px;
      width: 24px;
      .ant-picker-next-icon::before {
        width: 10px;
        height: 10px;
        border: 0 solid $default;
        border-width: 1.5px 0 0 1.5px;
      }
    }
  }
  .ant-picker-body {
    padding: 16px;
    .ant-picker-content {
      width: 326px;

      th {
        font-weight: $medium;
        font-size: $small;
        line-height: 22px;
        text-transform: uppercase;
        height: 22px;
      }
      tr {
        &:last-child {
          td {
            padding-bottom: 0;
          }
        }
      }
    }
    .ant-picker-cell {
      padding: 6px 0;
      color: #e0e0e0;
      &::before {
        border-radius: 123px;
      }
      .ant-picker-cell-inner {
        height: 32px;
        width: 32px;
        line-height: 34px;
        border-radius: 123px;
        font-size: $medium1;
        &::before {
          border-radius: 123px;
        }
      }
    }
    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        color: $default;
      }
    }
    .ant-picker-cell-in-view {
      &.ant-picker-cell-today {
        .ant-picker-cell-inner {
          &::before {
            border: none !important;
          }
        }
      }
      .ant-picker-cell-inner {
        color: $default;
      }
      &.ant-picker-cell-selected {
        .ant-picker-cell-inner {
          color: $white;
          background: $default;
        }
      }
    }
    .ant-picker-cell-disabled {
      &::before {
        background: transparent;
      }
      .ant-picker-cell-inner {
        color: #e0e0e0;
      }
    }
  }
}

.ant-picker-calendar {
  &-date {
    margin: 0 !important;
    padding: 8px 8px 16px 12px !important;
    border: 1px solid $border !important;
    height: 100% !important;

    &-value {
      text-align: left;
      font-weight: 500;
      width: 28px;
      height: 28px;
      line-height: 28px !important;
      color: $default !important;
    }

    &-today {
      .ant-picker-calendar-date-value {
        background-color: $primary;
        border-radius: 100%;
        text-align: center;
      }
    }

    &-content {
      height: auto !important;
      min-height: 46px !important;
      overflow-y: visible !important;

      ul {
        padding: 0;

        span {
          font-size: 10px;
        }
      }
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-calendar-date {
      background-color: rgba(115, 229, 255, 0.2) !important;
    }
  }

  .ant-picker-cell {
    visibility: hidden;
    pointer-events: none;

    &.ant-picker-cell-in-view {
      visibility: visible;
      pointer-events: visible;
    }
  }

  .ant-picker-panel {
    margin-top: 24px;

    tr:last-child {
      .ant-picker-cell {
        display: none;
        max-width: calc(100% / 7);
      }

      .ant-picker-cell-in-view {
        display: block;
      }
    }

    .ant-picker-body {
      padding: 0;
    }
  }

  .ant-picker-content {
    display: flex;
    flex-direction: column;

    thead {
      margin-bottom: 24px;

      tr {
        display: flex;

        th {
          text-align: center;
          color: $default;
          font-weight: 500;
          text-transform: uppercase;
          flex: 1;
        }
      }
    }

    tbody {
      display: flex;
      flex-direction: column;

      &::before {
        content: "-";
        display: block;
        line-height: 24px;
        color: transparent;
      }

      tr {
        display: flex;

        td {
          flex: 1;
        }

        &:last-child {
          td {
            &.ant-picker-cell-disabled {
              display: none;
            }
          }
        }
      }
    }
  }
}
.ant-picker-year-panel,
.ant-picker-decade-panel {
  width: 358px;
  .ant-picker-header-view {
    display: flex;
    align-items: center;
  }
  .ant-picker-cell-inner {
    width: auto !important;
  }
}

@media only screen and (max-width: 768px) {
  .ant-picker-calendar {
    &-date {
      padding: 4px !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border: none !important;

      &-value {
        text-align: center;
      }
    }

    .ant-picker-cell-selected,
    .ant-picker-cell:hover {
      .ant-picker-calendar-date {
        background-color: $white !important;
      }
    }
  }

  .ant-picker-time-panel {
    &-column {
      width: 60px !important;
    }
  }
}
