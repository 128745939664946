.ant-message {
  top: 40px;

  &-notice-content {
    padding: 12px 24px !important;
    background: rgba(18, 18, 18, 0.8) !important;
    border-radius: 123px !important;
  }

  .ant-message-info {
    .anticon {
      display: none;
    }

    span {
      color: $white !important;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .ant-message-loading {
    span {
      color: $white !important;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
