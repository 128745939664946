.confirm-delete-modal {
  .ant-modal-content {
    .ant-modal-header {
      border-bottom: none;
      padding: 20px 20px 8px 20px;
    }
    .ant-modal-body {
      padding: 20px;
      padding-top: 0;
      button {
        width: 100%;
        height: 50px;
      }
    }

    border-radius: 16px;
  }
}
