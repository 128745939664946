@import "./styles/index.scss";
@import "~rmc-date-picker/assets/index.css";
@import "~rmc-picker/assets/index.css";
@import "~rmc-picker/assets/popup.css";
@import "~swiper/swiper.min.css";
@import "react-loading-skeleton/dist/skeleton.css";

html,
body {
  font-family: $fontSFProDisplay !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fluid-container {
  width: 100%;
  height: 100%;
  max-width: 1138px !important;
  margin: 0 auto;
}

.fullwidth {
  width: 100%;
}

.container {
  width: 100%;
  max-width: $container !important;
  margin: auto;
  margin-top: 0;
}

.border-radius-modal {
  .ant-modal-content {
    border-radius: 16px !important;
  }
}

/* width */
::-webkit-scrollbar {
  z-index: -1;
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #979797;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #979797;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

/* React Toatify */
.Toastify__toast-container--top-center {
  min-width: 445px;
  z-index: 99999;

  .Toastify__toast.Toastify__toast--default {
    min-height: 56px;
    border-radius: 8px;
  }
}

@media only screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}

.empty-state {
  height: 255px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($white, 0.1);
  border-radius: 16px;
  margin: auto;
  margin-top: 24px;

  @include mobile {
    margin-top: 20px;
    width: calc(100% - 32px);
  }
  &--module {
    @include mobile {
      padding-top: 24px;
      align-items: flex-start;
    }
  }
  &--social-links {
    margin-top: 16px;
    height: 40px;
    z-index: 9;
    position: relative;

    @include mobile {
      height: 30px;
      margin-top: 10px;
    }
  }

  &--header {
    margin-top: 0;
    width: 366px;
    height: 461px;
    flex-direction: column;
    border-radius: 16px;

    @include mobile {
      width: 100%;
      height: 412px;
      border-radius: 0;
    }
  }
}
