$default: #121212;
$dark: #121212;
$darkSidebar: #1e1f20;
$primary: #60e5e8;
$blue: #257ffc;
$blue2: #5e64ff;
$pink: #ff07c9;
$white: #ffffff;
$border: #e9e9eb;
$border2: #e5e5e5;
$black: #000000;
$black2: #121727;
$gray: #e6e7e9;
$gray2: #f9f9f9;
$gray3: #e4e4e4;
$grey: #f4f3f3;
$grey2: #e6e6e6;
$grey3: #e0e0e0;
$grey4: #636363;
$gray2: #e6e6e6;
$gray3: #e0e0e0;
$gray4: #636363;
$gray5: #f5f5f5;
$gray6: #cdcdcd;
$gray7: #e6e6e6;
$gray8: #d3d3d3;
$gray9: #e9e9eb;
$gray10: #f4f3f3;
$gray11: #f1f1f1;
$gray12: #f3f3f3;
$red: #ff3c45;
$secondaryRed: #fc0d1b;
$inputBorder: #e4e4ed;
$checked: linear-gradient(360deg, #76ba18 2.22%, #76ba18 100%);
$button: linear-gradient(
  270deg,
  #17e391 21.47%,
  #58e5df 77.04%,
  #60e5e8 77.05%
);
$checkedBorder: #76ba18;
$blueDodger: #5e64ff;
$lavender: #cd5ce0;
$yellow: #ffba00;
$green: #76ba18;
$violet: #ec8efc;
$magenta: #cd5ce0;
$anakiwa: #8feaff;
$codGray: #141414;
$darkGray: #1a1a1a;
$orange: #ffa41c;
$tundola: #414141;
$mineShaft: #292929;
$text: #121212;
$borderRadius: 16px;
$warning: #ffa41c;
$success: #76ba18;
$alto: #e0e0e0;
$lightGreen: #50c32c;
$secondaryGrey: #c4c4c4;
$dusty_gray: #979797;
$serenade: #fff6e8;
$shark: #1e1f20;
$error: #ff3c45;
$grey5: #e0e0e0;
$mercury: #e6e6e6;

$successBackground: #f1f8e8;

$xx-small: 10px;
$x-small: 12px;
$small: 14px;
$xl-small: 15px;
$medium1: 16px;
$medium2: 18px;
$normal: 20px;
$normal1: 22px;
$normal2: 24px;
$large: 32px;
$larger: 36px;
$x-large: 40px;
$xx-large: 56px;
$xxl-large: 72px;

$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;

$container: 616px;

$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
$small-height: 650px;
$videoCallHeight: calc((50vw - 64px) * 720 / 1280);
$fontGotham: "Gotham Pro", sans-serif;
$fontSFProDisplay: "SFProDisplay", sans-serif;

$boxShadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0,
  rgba(60, 64, 67, 0.15) 0 1px 3px 1px;

$boxShadow2: 0 0 10px rgba(0, 0, 0, 0.25);
$boxShadow3: 0 0 15px rgba(0, 0, 0, 0.15);

$icons-url: "https://komi-assets.s3.amazonaws.com/icons";

// :root {
//   --ant-primary-color: #10848b;
//   --ant-success-color: rgba(255, 255, 255, 0.1);
//   --ant-info-color: #ffffff;
// }

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin smallHeight {
  @media (max-height: #{$small-height}) {
    @content;
  }
}

@mixin modalActions {
  display: flex;
  justify-content: flex-end;

  @include mobile {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .ant-btn {
    width: 123px;

    &:nth-child(1) {
      margin-right: 24px;
    }

    @include mobile {
      width: 100%;

      &:nth-child(1) {
        margin-top: 16px;
      }
    }
  }
}
