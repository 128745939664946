.ant-card {
  .ant-card-head {
    padding: 20px;
  }

  .ant-card-head-title {
    padding: 0;
  }

  .ant-card-body {
    padding: 20px;
  }
}
