@import "../abstracts//variables";

$total: 40;

@for $i from 0 through $total {
  .bo__radius--#{$i*2} {
    border-radius: #{$i * 2}px !important;
  }
  .bo__radius-tl--#{$i*2} {
    border-top-left-radius: #{$i * 2}px !important;
  }
  .bo__radius-tr--#{$i*2} {
    border-top-right-radius: #{$i * 2}px !important;
  }
  .bo__radius-bl--#{$i*2} {
    border-bottom-left-radius: #{$i * 2}px !important;
  }
  .bo__radius-br--#{$i*2} {
    border-bottom-right-radius: #{$i * 2}px !important;
  }
}

.border-box {
  // background-color: $white;
  border: 1px solid $gray3;
  box-sizing: border-box;
  border-radius: 16px;
}
.border {
  &--grey2 {
    background-color: $white;
    border: 1px solid $gray2 !important;
    box-sizing: border-box;
    border-radius: 8px;
  }
  &__solid--grey200 {
    border: 1px solid $border2;
  }
  &--primary {
    background:
      linear-gradient(#ffffff, #ffffff) padding-box,
      linear-gradient(to left, #17e391, #58e5df, #60e5e8) border-box;
    border: 1px solid transparent;
    display: inline-block;
  }
  &--none {
    border: none !important;
  }
  &__bottom {
    border-bottom: 1px solid $grey !important;
  }
}

.box-shadow-inner {
  &__top {
    box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.7);
  }
  &__bottom {
    box-shadow: inset 0 -7px 9px -7px rgba(0, 0, 0, 0.7);
  }
  &__left {
    box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.7);
  }
  &__right {
    box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.7);
  }
}
