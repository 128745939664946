@import "../../styles/abstracts/variables";

$minWidth: 700px;
$maxWidth: 1024px;

.livestream {
  padding: 40px;

  &--with-chat {
    padding: 40px 0 0 40px;
  }

  .video-placeholder {
    .ant-typography {
      display: none !important;
    }
  }

  &__chat {
    width: 336px;
    margin-left: 12px;
  }

  .stream-player {
    > div {
      border-radius: 8px;
      background-color: transparent !important;
    }

    video {
      max-height: calc(100vh - 330px) !important;
    }
  }

  .local-preview {
    justify-content: center;
    align-items: center;
    width: 704px;
    margin: auto;

    &__header {
      width: 100%;
      margin: auto;
      margin-bottom: 40px;
      border-radius: 8px 8px 0 0;
      min-width: $minWidth;
      max-width: $maxWidth;

      .ant-typography {
        color: $white;
        font-weight: $semi-bold;
        font-size: 18px;
      }

      &__dot {
        width: 10px;
        height: 10px;
        background-color: $red;
        border-radius: 100%;
      }

      &__info.ant-typography {
        font-weight: 500;
        font-size: 14px;
      }
    }

    &__container {
      justify-content: center;
      display: flex;
      align-items: center;
      background-color: $white;
      padding: 72px;

      &__camera {
        width: 100%;
        margin: auto;
        max-width: $maxWidth;

        video {
          display: block;
          width: 100% !important;
          max-height: 60vh;
          position: unset !important;
          height: 100%;
          border-radius: 8px;
          object-fit: contain !important;
          margin: auto !important;
        }
      }
    }

    &__action-button {
      width: 100%;
      max-width: $maxWidth;
      min-width: $minWidth;
      margin: auto;
      margin-bottom: 0;
      margin-top: 46px;

      .ant-btn {
        width: 56px !important;
        height: 56px !important;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        animation-duration: 0s !important;

        &:hover {
          background: $white;
        }

        &:active,
        &:focus {
          background: $white;
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }

      &__live.ant-btn {
        background-color: $red;
        width: 212px !important;
        font-size: 16px;
        color: $white;
        border-radius: 40px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);

        &:hover,
        &:active,
        &:focus {
          background: $red;
          opacity: 1;
        }

        &:disabled {
          background-color: rgba($red, 0.3) !important;
        }
      }

      &__finish.ant-btn {
        width: 196px !important;
        background-color: transparent;
        color: $white;
        border: 1px solid $white;
        border-radius: 40px;
        font-weight: $semi-bold;
        font-size: 16px;

        &:hover,
        &:active,
        &:focus {
          background: $white;
          color: $black;
          opacity: 1;
        }
      }
    }

    .video-track__video {
      height: auto !important;
    }

    .video-placeholder {
      width: 560px !important;
      height: 420px !important;

      &__container {
        height: 420px !important;
      }

      .ant-typography {
        display: none;
      }
    }

    .video-track {
      .ant-typography {
        display: none !important;
      }
    }
  }

  .ant-btn.btn-share-screen {
    padding: 16px;
    border: 1px solid $white !important;
    background-color: transparent;
    border-radius: 8px;
    width: 220px !important;

    &:focus,
    &:active {
      background-color: transparent !important;
    }

    &.ant-btn-red {
      border: 1px solid $red !important;
    }
  }

  &__actions__button {
    width: 72px !important;
    height: 72px !important;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    animation-duration: 0s !important;
    border-radius: 100% !important;

    svg path {
      stroke: $default;
    }

    &:hover {
      background: $white;
    }

    &:active,
    &:focus {
      background: $white;
    }

    &--finish {
      border-radius: 40px;
      width: 196px;
      height: 60px !important;
      background-color: transparent !important;

      &.ant-btn-red {
        border-width: 2px;
        height: 48px !important;
      }
    }

    &--end-call {
      background-color: $red !important;
      border-color: $red !important;

      svg path {
        stroke: $white;
      }

      &:hover {
        background: $red;
      }

      &:active,
      &:focus {
        background: $red;
      }
    }
  }
}
