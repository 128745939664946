@import "../../styles/abstracts/variables";

.avatar-profile {
  img {
    object-fit: cover;
    object-position: top;
  }

  &__text {
    color: $default;
    background-color: $primary;
    font-weight: 500;
  }

  &__info {
    line-height: 24px;
    display: block;
    font-size: 16px;
  }

  &__profession {
    color: rgba($black, 0.7);
    font-size: 14px;
    font-weight: normal !important;
  }
}
