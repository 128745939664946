button.ant-btn {
  height: 36px;
  box-shadow: none !important;
  text-shadow: none;
  border-radius: 123px;
  font-weight: $bold;
  font-size: $medium1;
  line-height: 24px;
  border: 2px solid $grey3;
  letter-spacing: 1px;
  &:hover,
  &:focus {
    color: $default;
    border: 2px solid $grey3;
    background-color: $grey3;
  }
  &:active {
    background-color: $white;
    color: $dusty_gray;
    border: 2px solid $gray11;
  }

  &.ant-btn-link {
    letter-spacing: unset;

    &.add-item-btn {
      padding-left: 0;
      height: 32px !important;
    }

    border: none;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }
  }

  &[disabled] {
    color: $dusty_gray !important;
    background-color: $gray12 !important;
    border: none;
    span,
    .ant-typography {
      color: $dusty_gray;
    }
  }

  &.ant-btn-text {
    border: none !important;
    letter-spacing: unset;

    &[disabled],
    &:focus {
      background-color: transparent !important;
      border: none;
    }
  }

  &.ant-btn-sm {
    height: 36px;
    padding: 0 15px;
  }

  &.ant-btn-md {
    height: 52px;
  }

  &.ant-btn-lg {
    height: 48px;
    border-radius: 123px;
    min-width: 158px;
  }

  &.ant-btn-xl {
    height: 52px;
    border-radius: 123px;
    min-width: 160px;
  }

  &-primary {
    border: none;
    color: $default;
    background: $default;
    font-weight: $bold;
    line-height: 24px;
    border-radius: 123px;
    text-transform: uppercase;
    letter-spacing: 1px;
    span,
    .ant-typography {
      color: $white;
    }

    &:focus,
    &:hover {
      border: none;
      background: rgba($default, 0.8);
    }
    &:active {
      background: rgba($default, 0.7);
    }
    &[disabled] {
      color: $dusty_gray;
      background-color: $grey3 !important;
      border: none;
    }
  }

  &-uppercase {
    > span {
      text-transform: uppercase;
    }
  }

  &-dangerous {
    border: none !important;
    color: $white !important;

    svg {
      path {
        stroke: $red;
      }
    }
  }

  &-red {
    border-color: $red !important;

    * {
      color: $red !important;
      stroke: $red;
    }
  }

  &-text {
    height: auto !important;

    &:hover {
      background: none !important;
    }
  }
  &-default {
    text-transform: uppercase;
  }
  &.btn--default {
    color: $default;

    &:hover,
    &:active {
      color: $default;
    }
  }

  &.btn--no-border {
    border: none;
    box-shadow: none;
  }

  &.btn--square {
    width: 48px;
    height: 48px;
    padding: 0;
  }

  &.btn--circle {
    border-radius: 100%;
  }

  &.dragdrop-button {
    height: 36px !important;
  }
}

button.ant-btn-link {
  &[disabled] {
    color: rgba($default, 0.5) !important;
    background-color: unset !important;
    border: none;

    > span {
      color: rgba($default, 0.5) !important;
    }
  }
}

.ant-switch {
  height: 20px;
  background-color: $grey4;
  min-width: 37.5px;
  &-handle {
    width: 16px;
    height: 16px;

    &::before {
      background-color: $white;
      box-shadow: none;
    }
  }

  &-checked {
    background-color: $green;

    .ant-switch-handle {
      left: calc(100% - 18px);

      &::before {
        background-color: $white;
      }
    }
  }
}
