@import "../../styles/abstracts/variables";

.main-header {
  height: 100%;
  position: relative;

  .komi-link {
    min-height: 44px;

    .ant-input-group-addon {
      background-color: $darkSidebar;
      border: 2px solid rgba(255, 255, 255, 0.1);

      > svg,
      .ant-btn {
        vertical-align: middle;
      }

      &:last-child {
        border-left: 0;
      }
    }

    .ant-input {
      color: $white;
      background-color: $darkSidebar;
      min-height: 44px;
      border-top: 2px solid rgba(255, 255, 255, 0.1);
      border-bottom: 2px solid rgba(255, 255, 255, 0.1);
      border-left: 0;
      border-right: 0;
    }

    .ant-btn {
      padding: 0;
    }
  }

  .btn-categories {
    background-color: rgba($primary, 0.2);
    border: none;
  }

  .ant-input-search {
    margin-left: 24px !important;
    max-width: 345px;
    border: 1px solid $border;
    padding: 8px 0 8px 16px;

    &-icon {
      padding-right: 16px;

      &::before {
        display: none;
      }
    }
  }

  &__dropdown-menu {
    box-shadow: 0 0 3px rgba($default, 0.2);
    padding: 16px !important;
    border-radius: 16px !important;

    .ant-dropdown-menu-item {
      height: auto;
      min-height: 44px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 16px !important;
      margin-top: 8px;
      border-radius: 8px;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        background-color: rgba($primary, 0.2);
      }

      &.switch-consumer {
        background-color: $primary;

        &:hover {
          background-color: $primary;
        }
      }

      a {
        color: $default;
        font-weight: 500;
      }

      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }

  .menu-button {
    padding: 0;
    padding-right: 12px;
    min-height: 24px;
    height: 24px !important;

    &__dark {
      svg path {
        stroke: $white;
      }
    }
  }
}
