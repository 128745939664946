.video-call-app {
  padding: 0 !important;
  height: 100%;
  display: flex;
  flex-direction: column;

  .btn-add-timer {
    width: 56px;
    height: 28px;
    border: none;
    border-radius: 123px;
    position: absolute;
    top: -16px;
    left: 63px;

    .ant-typography {
      width: 28px;
    }

    @include mobile {
      width: 49px;
      height: 20px;
      top: -8px;
      left: 53px;

      .ant-typography {
        width: 21px;
      }
    }
  }

  .livestream__chat {
    margin-left: 28px;
    margin-bottom: 16px;
  }

  .chat-box {
    border-radius: 16px;

    &__content {
      height: calc(100vh - 289px);
    }
  }

  #selfie {
    background-color: $default;
    padding: 32px;
    height: 100vh;
    width: 100%;

    @include mobile {
      padding: 24px 16px;
    }

    img {
      width: 100%;

      &:first-child {
        width: auto;
        height: auto;
        margin-bottom: 24px;
      }
    }
  }

  .selfie-wrapper {
    position: relative;
    background-color: $default;

    #selfie {
      margin-top: -80px;
    }

    &-content {
      background-color: $default;
      width: 100%;
      height: calc(100vh - 80px);
      overflow: hidden;
      position: absolute;
      z-index: 9;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      left: 50%;
      transform: translate(-50%, 0);

      * {
        max-width: 492px;
      }

      .list-avatar {
        .avatar-profile:last-child {
          margin-left: -8px;
        }
      }

      button {
        max-width: 393px;
      }

      @include mobile {
        padding: 0 32px;

        img {
          width: 228px;
        }

        button {
          max-width: 100%;
        }
      }
    }

    &--has-selfie {
      .selfie-wrapper-content {
        * {
          max-width: 508px;
        }

        @include mobile {
          padding: 20px 24px 0;
          justify-content: flex-start;
        }
      }
    }
  }

  &.screenshot {
    .mic-off {
      display: none;
    }
  }

  &.end {
    height: calc(100vh - 80px);
  }
}
