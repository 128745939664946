.expert-terms {
  height: 100vh;
  overflow: auto;
  padding: 24px 16px;
}

@media only screen and (max-width: 768px) {
  .terms {
    padding-bottom: 100px;
  }
}
