.video-call-wait-room {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    flex: 1;
    max-width: 1200px;

    &--camera {
      width: 100%;
      margin: auto;
      border-radius: 8px;

      .video-placeholder {
        border-radius: 8px;

        &__container {
          height: 420px !important;
        }

        .ant-typography {
          display: none;
        }
      }

      video {
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: scaleX(-1);
      }
    }

    &--actions {
      position: absolute;
      transform: translate(-50%, 0);
      left: 50%;
      bottom: 20px;

      &--tray {
        position: relative;
      }

      .report-icon {
        position: absolute;
        right: -5px;
        top: -5px;
      }

      .permission-disable {
        cursor: not-allowed;
        pointer-events: none;
        background-color: $white !important;

        .icon {
          opacity: 0.2;
        }

        .report-icon {
          opacity: 1;
        }
      }

      .ant-btn {
        width: 48px !important;
        height: 48px !important;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        animation-duration: 0s !important;

        &:hover {
          background: $white;
          opacity: 0.7;
        }

        &:active,
        &:focus {
          background: $white;
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &__devices {
    &--select {
      border-radius: 8px;
      margin-top: 16px;

      .ant-select-selector {
        height: 56px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
        justify-content: center;
        align-items: center;
      }

      .ant-select-selection-item {
        line-height: 24px !important;
        background-color: $white !important;
      }

      span {
        &.ant-select-arrow,
        &.ant-select-selection-search,
        &.ant-select-selection-item,
        &.video-call-wait-room__devices--select--option {
          font-size: 14px !important;
          font-weight: 500 !important;
          color: $dark;
          height: 54px !important;
        }
      }

      .ant-select-arrow {
        height: auto !important;
        margin: auto !important;
        transform: translate(0, -50%);
        right: 16px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &--select--option {
      display: flex;
      align-items: center;

      > svg {
        margin-right: 10px;
      }
    }
  }

  &__information {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: flex-start;

    &--tag {
      border-radius: 4px;
      text-align: center;
    }

    &--suggest {
      padding: 20px;
      border-radius: 8px;
    }
  }

  .ant-select-selector {
    border-radius: 8px !important;
  }

  .ant-select-selection-item {
    display: flex !important;
    align-items: center;

    > svg {
      margin-right: 10px;
    }
  }
}
