.tray {
  padding: 40px 32px;

  @include mobile {
    padding: 38px 16px 16px;
  }

  .btn-selfie {
    height: 40px !important;
    padding: 0 12px !important;

    .ant-typography {
      font-weight: 600 !important;
      letter-spacing: 0 !important;
    }
  }

  .livestream__actions {
    &__button {
      border: none;
      width: 40px !important;
      height: 40px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $mineShaft;

      svg {
        width: 20px !important;
        height: 20px !important;

        * {
          stroke: $white;
        }
      }

      &.active {
        background-color: $primary;
      }

      &--tip {
        border: 2px solid $gray5;
        height: 48px !important;
      }

      &.ant-btn-dangerous {
        border: none !important;
        color: $red !important;
        background-color: $red !important;
      }
    }

    &__tag {
      height: 22px;
      border-radius: 4px;
      display: flex;
      border: none;
      align-items: flex-end;

      span {
        line-height: 20px;
        height: 20px;
        display: block;
      }
    }
  }

  .ant-btn.btn-share-screen {
    width: auto !important;
    border: none !important;
    height: 100% !important;

    &:hover,
    &:active,
    &:focus {
      border: none !important;
    }

    svg * {
      stroke: $default;
    }

    &.ant-btn-red {
      border: none !important;

      &:hover,
      &:active,
      &:focus {
        border: none !important;
      }

      svg * {
        stroke: $red;
      }
    }
  }
}
