@import "../../styles/abstracts/variables";

.loading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transform: unset;
  z-index: 99;

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
  }

  &--full {
    width: 100%;
    height: 100%;
    transform: translate(0, 0);
    left: 0;
    top: 0;
    background-color: $white;
    opacity: 0.1;
  }

  &--blur {
    background-color: #ffffffaf;
  }
}
