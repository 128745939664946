$total: 160;

@for $i from 0 through $total {
  // Margin
  .m__t--#{$i*2} {
    margin-top: #{$i * 2}px !important;
  }
  .m__b--#{$i*2} {
    margin-bottom: #{$i * 2}px !important;
  }
  .m__r--#{$i*2} {
    margin-right: #{$i * 2}px !important;
  }
  .m__l--#{$i*2} {
    margin-left: #{$i * 2}px !important;
  }
  .m__y--#{$i*2} {
    margin-top: #{$i * 2}px !important;
    margin-bottom: #{$i * 2}px !important;
  }
  .m__x--#{$i*2} {
    margin-left: #{$i * 2}px !important;
    margin-right: #{$i * 2}px !important;
  }
  .m--#{$i*2} {
    margin: #{$i * 2}px !important;
  }

  // Padding
  .p__t--#{$i*2} {
    padding-top: #{$i * 2}px !important;
  }
  .p__b--#{$i*2} {
    padding-bottom: #{$i * 2}px !important;
  }
  .p__r--#{$i*2} {
    padding-right: #{$i * 2}px !important;
  }
  .p__l--#{$i*2} {
    padding-left: #{$i * 2}px !important;
  }
  .p__y--#{$i*2} {
    padding-top: #{$i * 2}px !important;
    padding-bottom: #{$i * 2}px !important;
  }
  .p__x--#{$i*2} {
    padding-left: #{$i * 2}px !important;
    padding-right: #{$i * 2}px !important;
  }
  .p--#{$i*2} {
    padding: #{$i * 2}px !important;
  }

  // Top
  .top--#{$i*2} {
    top: #{$i * 2}px !important;
    bottom: initial !important;
  }

  // Bottom
  .bottom--#{$i*2} {
    bottom: #{$i * 2}px !important;
    top: initial !important;
  }

  // Right
  .right--#{$i*2} {
    right: #{$i * 2}px !important;
    left: initial !important;
  }

  // Left
  .left--#{$i*2} {
    left: #{$i * 2}px !important;
    right: initial !important;
  }

  .text__line-height--#{$i*2} {
    line-height: #{$i * 2}px !important;
  }

  .height--#{$i*2} {
    height: #{$i * 2}px !important;
  }
  .width--#{$i*2} {
    width: #{$i * 2}px !important;
  }

  // border radius
  .border__radius--#{$i*2} {
    border-radius: #{$i * 2}px !important;
  }

  // witdh
  .w--#{$i*2} {
    width: #{$i * 2}px !important;
  }
  // min witdh
  .min__w--#{$i*2} {
    min-width: #{$i * 2}px !important;
  }
}

.m {
  &__x--auto {
    margin: 0 auto !important;
  }
  &__b--5 {
    margin-bottom: 5px !important;
  }
  &__b--3 {
    margin-bottom: 3px !important;
  }
  &__t--5 {
    margin-top: 5px !important;
  }
}

.p {
  &--0 {
    padding: 0 !important;
  }
}
.min-h {
  &-auto {
    min-height: auto !important;
  }
  &-full {
    min-height: 100% !important;
  }
}
.full-height {
  height: 100% !important;
}

.full-width {
  width: 100% !important;
}
.width {
  &--71 {
    width: 71px;
  }
}
.flex--1 {
  flex: 1;
}
.-my-8 {
  margin-top: -8px;
  margin-bottom: -8px;
}
.-mt-4 {
  margin-top: -4px;
}
.w-186 {
  width: 186px !important;
}
