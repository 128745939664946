@import "../../styles/abstracts/variables";

.preview-layout {
  background-color: $default;
  padding-bottom: 32px;

  &__header.ant-layout-header {
    padding: 32px 0;
    z-index: 100;
    background-color: $dark;
    line-height: 44px;
  }

  &__content {
    border-radius: 0;
    background-color: $dark;
  }

  &__page-content {
    padding: 32px;
    width: 100%;
    position: relative;
    background-color: $grey;
    border-radius: 16px;
    min-height: calc(100vh - 140px);

    &--trial-banner {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      min-height: calc(100vh - 192px);
    }

    &--banner-dismissed {
      border-top-left-radius: 16;
      border-top-right-radius: 16;
    }
  }

  &--additional-page {
    .preview-layout__page-content {
      padding: 32px 0;
    }

    &-fan-club {
      .preview-layout__page-content {
        padding: 36px 0;
      }
    }

    &-team-member {
      .preview-layout__page-content {
        padding: 36px 0;
      }
    }

    &-account-setting {
      .preview-layout__page-content {
        padding: 24px 0;
      }
    }
  }
}
