@import "../../styles/abstracts/variables";

.main-layout {
  background-color: $dark;

  &--dark-mode {
    background-color: $default;

    .ant-typography {
      color: $white;
    }
  }

  &__header.ant-layout-header {
    position: sticky;
    top: 0;
    z-index: 999;
    padding: 0;
    background-color: $dark;
    height: 80px;
  }

  &__content {
    min-height: calc(100vh - 81px);
    border-radius: 0;
    background-color: $dark;
  }

  &__page-content {
    padding: 32px;
    width: 100%;
    position: relative;

    &--no-sidebar {
      border-radius: 16px 16px 0 0 !important;
    }
  }

  .side-bar-wrapper {
    position: relative;
    border-radius: 16px 0 0 0;
    background-color: $darkSidebar;
  }

  .ant-layout-sider {
    position: sticky;
    z-index: 99;
    border-radius: 16px 0 0 0;
    background-color: $darkSidebar;
  }

  .ant-layout-content {
    min-height: calc(100vh - 81px);
    background-color: $gray10;
    border-radius: 0 16px 0 0;
  }

  .ant-layout-footer {
    padding: 0;
    background: $white;
  }

  &--profile {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }

  &--livestream {
    .ant-layout-header {
      max-width: 100% !important;
    }

    .main-layout__header {
      padding: 0 48px;
      background: $default;
      margin-bottom: 0;

      .ant-dropdown-trigger {
        padding-right: 0;
      }

      .mobile-header--left {
        @include mobile {
          display: none !important;
        }
      }

      .ant-col {
        @include mobile {
          padding-left: 24px;
        }
      }

      .mobile-header--right {
        @include mobile {
          padding-right: 8px;
        }
      }
    }

    .komi-link {
      display: none;
    }

    .main-layout__content {
      min-height: 100vh;
      margin-left: 0;
      background: $default;
      display: flex;
      flex-direction: column;

      .main-layout__page-content {
        min-height: 0;
        padding: 0 !important;
        background: $default !important;
      }

      .fluid-container {
        width: 100% !important;
        max-width: 100% !important;
      }

      .container {
        width: 100% !important;
        max-width: 100% !important;
      }
    }

    &.end {
      .main-layout__content {
        min-height: calc(100vh - 81px);
      }
    }

    .btn-create-experience {
      display: none;
    }

    &--waiting {
      .main-layout__header {
        background: $white;
        margin-bottom: 1px;
      }

      .main-layout__content {
        background: $white;

        .live-stream-app {
          height: calc(100vh - 81px);
        }
      }
    }
  }

  &--experience {
    height: calc(100vh - 76px);

    .main-layout__content {
      .ant-layout-content {
        overflow-x: visible;
      }
    }

    .loading {
      width: 100% !important;
      left: 0 !important;
    }
  }

  &--full {
    .main-layout__content {
      margin-left: 0;
    }
  }
}

@media only screen and (max-height: 767px) {
  .main-layout {
    .ant-layout-sider {
      position: relative !important;
    }

    aside.ant-layout-sider {
      min-height: 100% !important;
      height: auto !important;
      top: 0 !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main-layout {
    min-height: 100vh;

    &__page-content {
      padding: 16px;
    }

    .side-bar-wrapper {
      border-radius: 0;
    }

    .ant-layout-sider {
      display: none;
      border-radius: 0;
    }

    .ant-layout-content {
      border-radius: 0;
    }

    .main-layout__header {
      padding: 0 16px;
    }

    .main-layout__content {
      margin-left: 0;
    }

    .fluid-container,
    .container {
      max-width: 100% !important;
    }
  }
}
