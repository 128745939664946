@import "../abstracts/variables";
.d {
  &--flex {
    display: flex !important;
  }

  &--block {
    display: block !important;
  }

  &--none {
    display: none !important;
  }
  &--inline-flex {
    display: inline-flex !important;
  }
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.align__items {
  &--center {
    align-items: center;
  }

  &--end {
    align-items: flex-end;
  }

  &--start {
    align-items: flex-start;
  }
}
.pointer-none {
  pointer-events: none;
}
.align__self {
  &--center {
    align-items: center;
  }

  &--end {
    align-items: flex-end;
  }

  &--start {
    align-items: flex-start;
  }
}

.justify__content {
  &--center {
    justify-content: center;
  }

  &--end {
    justify-content: flex-end;
  }
  &--between {
    justify-content: space-between;
  }
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}
.items {
  &-center {
    align-items: center;
  }
}
.justify-between {
  justify-content: space-between;
}
.flex {
  display: flex;
  &.flex-col {
    flex-direction: column;
  }
  &.flex-1 {
    flex: 1 !important;
  }
  &--1 {
    flex: 1 !important;
  }

  &__direction {
    &--column {
      flex-direction: column;
    }
  }
}

.position {
  &--relative {
    position: relative !important;
  }
}

.visibility {
  &--hidden {
    visibility: hidden;
  }
}

.desktop__only {
  display: block;
}

.mobile__only {
  display: none;
}

.clickable {
  cursor: pointer;
}

.opacity {
  &--80 {
    opacity: 0.8;
  }
  &--50 {
    opacity: 0.5;
  }
  &--60 {
    opacity: 0.6;
  }
  &--40 {
    opacity: 0.4;
  }
  &--20 {
    opacity: 0.2;
  }
}

.cursor {
  &-pointer {
    cursor: pointer !important;
  }

  &-not-allowed {
    cursor: not-allowed !important;
  }
}

@media only screen and (max-width: 768px) {
  .desktop__only {
    display: none;
  }

  .mobile__only {
    display: block;
  }
}
.uppercase {
  text-transform: uppercase;
}
.divider {
  height: 1px;
  width: 100%;
  background: $grey;
  &.grey3 {
    background: $grey3;
  }
  &.white {
    background: $white;
  }
}
.divider-vertical {
  height: 100%;
  width: 1px;
  background: $white;
  &.border2 {
    background: $border2;
  }
}

.opacity--05 {
  opacity: 0.5;
}
.opacity--03 {
  opacity: 0.3;
}
.opacity--04 {
  opacity: 0.4;
}
.opacity--1 {
  opacity: 1 !important;
}
.opacity--08 {
  opacity: 0.8;
}
.opacity--09 {
  opacity: 0.9;
}
.opacity--07 {
  opacity: 0.7;
}
.opacity--06 {
  opacity: 0.6;
}
