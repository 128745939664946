.ant-modal {
  &-content {
    border-radius: 16px;

    .ant-modal-close {
      top: 20px;
      right: 20px;

      &-x {
        width: 28px;
        height: 28px;
        line-height: 28px;

        svg {
          height: 56px;
          height: 24px;
        }
      }
    }
  }

  &-title {
    line-height: 28px;
  }

  &-header {
    background-color: rgba($primary, 0.02);
    padding: 20px;
    border-bottom: 1px solid $grey3;
    border-radius: $borderRadius $borderRadius 0 0;
    .ant-modal-title {
      text-align: left;
      font-weight: $semi-bold;
      font-size: $medium2;
    }
  }

  .ant-form-item {
    flex-direction: column;

    &-label {
      padding-bottom: 6px !important;
      opacity: 0.8;
    }
  }
  &-body {
    padding: 24px;
  }
}

//fix for svg component loading in through storybook to avoid touching vn code and breaking other modals
.cancel-subscription {
  svg[name="closeModal"] {
    height: 28px;
  }
}