.ant-tabs {
  &-tab {
    color: $default;

    &:hover {
      color: $default;
    }

    &-active {
      .ant-tabs-tab-btn {
        color: $default !important;
        font-weight: $semi-bold !important;
      }
    }
  }

  &-nav {
    margin: 0 !important;

    &-more {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  &-ink-bar {
    bottom: -1px !important;
    border-radius: 8px 8px 0 0;
  }
}
