// Background Color
.bg {
  &--dark {
    background-color: $dark;
  }

  &--primary {
    background-color: $primary;
  }

  &--blue {
    background-color: $blue;
  }

  &--white {
    background-color: $white;
  }

  &--red {
    background: $red !important;
  }

  &--magenta {
    background-color: $magenta;
  }

  &--green {
    background-color: $green;
  }

  &--blue2 {
    background-color: $blue2;
  }

  &--orange {
    background-color: $orange !important;
  }
  &--warning {
    background: $warning !important;
  }
  &--grey {
    background: $grey !important;
  }
  &--success {
    background: $successBackground !important;
  }
  &--serenade {
    background: $serenade !important;
  }
  &--gray12 {
    background: $gray12 !important;
  }
}
