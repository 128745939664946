@import "../../styles/abstracts/variables";

.experience-content-card {
  flex-direction: column;
  border-radius: 16px;
  border: 2px solid $gray5;
  padding: 16px;
  margin-bottom: 16px;

  &__profile-picture {
    img,
    .ant-skeleton-avatar {
      width: 96px !important;
      height: 113px !important;
      border-radius: 8px;
      object-fit: cover;
      object-position: top;
    }
  }

  &__header {
    flex-direction: column;
    padding-bottom: 12px;

    &__status {
      padding: 0 6px;
      height: 20px;
      line-height: 20px;
      color: $white;
      font-weight: $semi-bold;
      width: auto;
      border-radius: 4px;

      &--active {
        background-color: $green;
      }

      &--draft {
        background-color: $gray8;
      }

      &--unpublished {
        background-color: $yellow;
      }
    }

    .btn-dragdrop {
      padding-right: 0;
      float: right;
    }
  }

  &__content {
    padding-top: 12px;

    svg {
      width: 24px;

      g {
        opacity: 1;
      }

      path {
        stroke: $default;
      }
    }

    &__btn-actions {
      > button:first-child {
        padding-left: 0;
      }
      .ant-btn-link {
        border-radius: 0 !important;
        padding: 10px 15px 5px 15px;
      }

      border-top: 1px solid $gray5;
      span {
        color: $default;
      }
      &__danger {
        span {
          color: $red;
        }
      }
    }
  }

  &__info {
    flex: 1;
    svg {
      margin-right: 5px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .experience-content-card {
    &__profile-picture {
      img,
      .ant-skeleton-avatar {
        width: 126px !important;
        height: 173px !important;
      }
    }

    &__header {
      span {
        font-size: 12px !important;
      }

      h1 {
        font-size: 14px !important;
        margin: 4px 0 0 !important;
      }

      &__price {
        margin-top: 0 !important;
      }
    }

    &__content {
      font-size: 12px !important;

      &__btn-actions {
        display: none;
      }
    }
  }
}
