@import "../abstracts/variables";

.noti-message {
  .ant-message-notice-content {
    padding: 8px !important;
    background: #fff !important;
    border-radius: 8px !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15) !important;
  }

  .ant-message-custom-content {
    display: flex;
    align-items: center;

    > svg {
      width: 20px !important;
      height: 20px !important;
    }

    > span {
      height: 24px !important;
    }
  }

  .notification-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;

    > .ant-typography {
      height: 20px;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: $darkGray;
    }

    &__close {
      width: 24px;
      height: 24px;
      margin-left: 16px;
      cursor: pointer;
    }
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
