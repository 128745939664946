input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@import "../../styles/abstracts/variables";

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ant-radio {
  &:hover,
  &:active,
  &:focus {
    .ant-radio-inner {
      border-color: $checkedBorder !important;
    }
  }

  &-checked {
    .ant-radio-inner {
      border-color: $checkedBorder !important;
      background: $checked;

      &::after {
        background-color: $white;
      }
    }
    &::after {
      border-color: $checkedBorder !important;
    }
  }
  &-wrapper {
    color: rgba($default, 0.8) !important;
    font-size: 14px;
    line-height: 22px;
  }
}

.ant-input {
  &:focus {
    box-shadow: none !important;
  }
}

.ant-select-white-bg {
  .ant-select-selector {
    height: 48px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    justify-content: center;
    align-items: center;
  }

  .ant-select-selection-item {
    background-color: $white !important;
  }

  span {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: $dark;
  }
}
.ant-input-number {
  width: 100%;
}

// checkbox
.ant-checkbox-group {
  width: 100%;
}

.ant-checkbox {
  &-inner {
    border-radius: 4px !important;
  }

  &:hover,
  &:active,
  &:focus {
    .ant-checkbox-inner {
      border-color: $checkedBorder !important;
    }
  }

  &-checked {
    .ant-checkbox-inner {
      border-color: $checkedBorder !important;
      background: $checked;
    }
    &::after {
      border-color: $checkedBorder !important;
    }
  }
}

.ant-form-item {
  font-weight: 500;
  label {
    height: auto;
  }
  &-has-success {
    label {
      opacity: 0.8;
    }
  }
  &-label {
    padding-bottom: 16px !important;

    label {
      font-weight: $regular;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &-control-input {
    .ant-input-prefix {
      font-weight: $semi-bold;
      font-size: $medium1;
    }

    .ant-input:not([disabled]):not([type="submit"]):not([type="password"]) {
      &:hover,
      &:focus,
      .ant-input-focused {
        background: linear-gradient(#ffffff, #ffffff) padding-box,
          linear-gradient(to left, #17e391, #58e5df, #60e5e8) border-box;
        border: 1px solid transparent;
      }
    }

    input,
    textarea {
      height: 52px;
      padding-left: 14px;
      font-weight: $semi-bold;
      font-size: $medium1;
      line-height: 22px;
      &::placeholder {
        color: $grey4;
        font-weight: $regular;
        line-height: 22px;
      }
    }
    .ant-input-number {
      &-input {
        height: 46px;
      }
    }
    textarea {
      padding-left: 16px;
      padding-right: 16px;

      &::placeholder {
        color: rgba(#011844, 0.5);
      }
    }
    input.ant-input-lg {
      height: 52px;
    }
    .ant-input-password,
    .ant-picker,
    .ant-select-selector {
      border-radius: 8px !important;
    }

    .ant-select {
      &-arrow {
        width: 24px;
        height: 24px;
        height: 100%;
        display: flex;
        top: 0;
        margin-top: 0;
        align-items: center;

        .anticon-down {
          background: url("#{$icons-url}/down.svg");
          width: 24px;
          height: 24px;
          svg {
            display: none;
          }
        }
      }
    }

    // input size
    .ant-input-lg {
      height: 52px;
    }

    .ant-input-xl {
      height: 56px;
    }
  }
  .ant-input-password,
  .ant-input-number {
    .ant-input {
      &:hover,
      &:focus,
      .ant-input-focused {
        background: none !important;
        border: none !important;
      }
    }
  }
  .ant-form-item-explain {
    &-error {
      font-weight: 400;
      color: $error;
      font-size: $x-small !important;
      line-height: 16px;
      margin-top: 4px;
    }
  }
  .phone-country-input {
    input {
      width: 100%;
      height: 48px;
      font-size: 16px;
      border-radius: 8px;
      background: $white !important;
      border: 1px solid $grey3 !important;
      padding-left: 99px;
      font-weight: $semi-bold;
      line-height: 22px;
      &::placeholder {
        color: rgba($default, 0.5);
        font-weight: $regular;
      }
    }
    .selected-flag {
      width: 40px;
    }
    .flag-dropdown {
      padding: 12px 32px 10px 14px;
      border: 1px solid $grey3 !important;
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      background: $white;
      .arrow {
        width: 24px;
        height: 24px;
        background: url("#{$icons-url}/arrow/down.svg") no-repeat;
        border: none;
        top: -5px;
        left: 30px;
        &.up {
          border-bottom: none !important;
        }
      }
    }
    .country-list {
      width: 294px !important;
      max-height: 336px !important;
      border: none !important;
      background: #ffffff;
      border-radius: 16px !important;
      margin: 8px 0 0 -1px !important;
    }
  }
}

.ant-picker:not(.ant-picker-disabled) {
  &:hover,
  .ant-picker-focused {
    background: linear-gradient(#ffffff, #ffffff) padding-box,
      linear-gradient(to left, #17e391, #58e5df, #60e5e8) border-box;
    border: 1px solid transparent;
  }
}

.ant-input-group-addon {
  background-color: $white;
  font-weight: $semi-bold;
}

.ant-radio-group {
  width: 100%;

  .ant-radio-button-wrapper {
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    margin-left: 24px;
    border: 1px solid $gray3;

    &:hover {
      color: $default;
    }

    &:first-child {
      margin-left: 0;
    }

    &::before {
      display: none;
    }

    &.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]) {
      color: $default;
      border: 1px solid $primary;
    }
  }
}

.ant-select {
  &:not(.ant-select-disabled) {
    &:hover {
      .ant-select-selector {
        background: linear-gradient(#ffffff, #ffffff) padding-box,
          linear-gradient(to left, #17e391, #58e5df, #60e5e8) border-box;
        border: 1px solid transparent;
      }
    }
  }
  &.ant-select-focused:not(.ant-select-disabled) {
    .ant-select-selector {
      background: linear-gradient(#ffffff, #ffffff) padding-box,
        linear-gradient(to left, #17e391, #58e5df, #60e5e8) border-box;
      border: 1px solid transparent;
      border-color: transparent !important;
    }
  }
  &-dropdown {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  }

  &-selector {
    padding: 0 16px !important;
  }

  &-selection-item {
    color: $default;
    font-weight: $medium !important;
    font-size: $medium1 !important;
    line-height: 22px !important;
    border: none !important;
    align-items: center;
    padding-right: 8px !important;

    &-remove {
      background-color: rgba($color: $dark, $alpha: 0.4);
      width: 14px;
      height: 14px;
      border-radius: 100%;
      display: flex !important;
      align-items: center;

      svg {
        width: 14px;
        height: 14px;

        path {
          stroke: $white;
        }
      }
    }
  }

  input {
    margin-left: 0 !important;
  }

  .ant-select-selection-placeholder {
    left: 16px;
  }
}

input:focus {
  caret-color: $primary;
}

.ant-form-item-has-error {
  input:focus {
    caret-color: $red;
  }
}

textarea {
  padding: 16px !important;
}

.currency-select {
  .ant-select-selector {
    height: 60px !important;
  }

  .ant-select-selection-search > input {
    margin-left: 5px !important;
    height: 60px !important;
    align-self: center;
  }

  .ant-select-selection-item {
    align-self: center;
    color: $default;
    background-color: $white !important;
  }
}
