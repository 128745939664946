@import "../../styles/abstracts/variables";

.stripe-connect-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fffffff2;
  z-index: 999;

  &__content {
    top: 50%;
    position: fixed;
    padding: 0 24px;
  }
}

.experience-content {
  padding-bottom: 24px;

  &__header {
    z-index: 99;
  }

  .ant-tabs-nav {
    z-index: 99;
  }

  .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid $border;

    .ant-tabs-tab:first-child {
      margin: 0 !important;
    }

    .ant-tabs-tab {
      //margin-right: 0;
      flex: 1;
      justify-content: center;
      padding: 16px 0;
      margin: 0 0 0 25px !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .experience-content {
    &__header {
      top: 0;
      align-items: center;
      width: 100%;
      padding: 0 16px;

      .ant-typography {
        font-size: 16px;
      }
    }

    .ant-tabs-nav {
      top: 65px;
      width: 100%;
      overflow: scroll;

      &-wrap {
        overflow: visible !important;
      }
    }

    .ant-tabs-content {
      margin-top: 110px;
    }

    &__wrapper {
      padding: 16px;
    }
  }
}
