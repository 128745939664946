@import "../../styles/abstracts/variables";

.experience-type-tag {
  font-size: 14px;
  font-weight: $semi-bold;
  line-height: 22px;
  padding: 2px 8px;
  color: $white;
  background-color: $red;
  display: inline-block;
  border-radius: 4px;

  &.live_class {
    background-color: $orange;
  }

  &.interactive_live_class {
    background-color: $red;
  }

  &.exclusive_content {
    background-color: $green;
  }

  &.one_to_one {
    background-color: $blueDodger;
  }

  &.course {
    background-color: $lavender;
  }

  &.bundle {
    background-color: $pink;
  }
  &__title {
    max-width: 224px;
  }
}
