@import "../../styles/abstracts/variables";

.stripe-connect-btn {
  display: flex;
  align-items: center;

  &.btn-primary {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    height: 44px !important;
    background-color: $default;
    color: $white;
    padding: 0 16px !important;
    border: none;
  }
}

.ant-btn.ant-tooltip-open {
  display: flex;
  align-items: center;
}

.verified-icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
}

.verified-icon-small {
  width: 16px !important;
  height: 16px !important;
}

.payout-tooltip {
  z-index: 100;
}
