.ant-table-wrapper {
  .ant-table {
    .ant-typography {
      font-weight: $semi-bold;
    }

    &-thead {
      tr {
        th {
          background-color: $white;
          font-weight: normal;
        }
      }
    }
  }
}
