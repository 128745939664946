.image-skeleton {
  img {
    object-fit: cover;
    //object-position: top;
  }
  .avatar-profile__text {
    font-weight: 500;
  }
  &--circle {
    img {
      border-radius: 100% !important;
    }
  }
}
