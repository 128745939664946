.rmc-picker-popup-header {
  .rmc-picker-popup-item {
    font-size: 14px;

    &.rmc-picker-popup-title {
      font-size: 16px;
      color: $default;
    }

    &.rmc-picker-popup-header-right {
      text-transform: uppercase;
    }
  }
}

.rmc-picker-popup-mask {
  z-index: 99990;
}

.rmc-picker-popup-wrap {
  z-index: 99999;
}

.rmc-picker-item {
  &.rmc-picker-item-selected {
    color: $default;
  }
}
