$fonts-url: "https://komi-assets.s3.amazonaws.com";
$sf-pro-display-url: "#{$fonts-url}/SFProDisplay";

@font-face {
  font-family: "SFProDisplay";
  src: url("#{$sf-pro-display-url}/SFUIDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("#{$sf-pro-display-url}/SFUIDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("#{$sf-pro-display-url}/SFUIDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("#{$sf-pro-display-url}/SFUIDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
