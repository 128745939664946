/* 
 * Safari doesn't support `border-radius` when drawing `outline`, but this will
 */
@mixin rounded-outline(
  $outline-width,
  $outline-style,
  $outline-color,
  $border-radius
) {
  position: relative;

  &::before {
    content: "";
    position: absolute;

    top: -$outline-width;
    bottom: -$outline-width;
    left: -$outline-width;
    right: -$outline-width;

    border-width: $outline-width;
    border-style: $outline-style;
    border-color: $outline-color;
    border-radius: $outline-width + $border-radius;
  }
}

.ssx-subscription-banner {
  &__container {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;

    &--icon {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 4px;

      width: 24px;
      height: 24px;
    }

    &--message {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: white;
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 12px;

    $radius: 8px;
    @include rounded-outline(2px, solid, #ffffff, $radius);
    border-radius: $radius;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: white;

    cursor: pointer;

    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      color: white;
    }
  }
}
