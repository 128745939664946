@import "../../styles/abstracts/variables";
.dropdown-menu {
  background-color: #121212;
  &__text {
    color: $white !important;
  }
  &__items {
    padding: 0;
    min-width: 198px;
    border-radius: 16px;
    box-shadow: $boxShadow3;
    margin-top: 8px;
    margin-left: 16px;
    .ant-dropdown-menu-item {
      padding: 16px 20px;
      border-bottom: 1px solid $grey3;
      &:last-child {
        border-bottom: none;
      }
    }
    &.account-menu {
      width: 294px;
      max-height: 440px;
      overflow: auto;
      padding: 0 !important;
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      &:first-child {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
      &:last-child {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      background-color: rgba(37, 127, 252, 0.05);
    }
  }
  .btn-login {
    color: $white;
    font-size: 1rem;
    font-weight: 600;
  }

  .komi-link {
    background-color: $darkSidebar;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 8px;
    .ant-btn {
      padding: 0;
      min-height: auto;
      height: 24px;
    }
    .ant-typography {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .actived {
    background: rgba(96, 229, 232, 0.1);
    border-radius: 123px;
    padding: 8px 16px;
    svg > path {
      stroke: $primary;
    }
  }

  .image-skeleton {
    height: 44px;
  }
}

.truncate {
  display: block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.request-talent-profile-modal {
  &.collaborator-invitation {
    margin-top: 66px;

    .ant-modal-body {
      max-height: 60vh;
    }
  }

  .ant-modal-body {
    padding-bottom: 32px;
  }

  .ant-modal-footer {
    background-color: $grey;
    border-top: none;
    padding: 0;
    text-align: center !important;
    border-radius: 0 0 16px 16px;

    .divider {
      height: 2px;
      background: linear-gradient(
        270deg,
        #17e391 21.47%,
        #58e5df 77.04%,
        #60e5e8 77.05%
      );
    }

    img,
    svg {
      margin: 16px 0 !important;
    }
  }

  .error-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    background: #ffeaee;
    gap: 8px;
    border-radius: 8px;
  }

  .ant-input-disabled {
    opacity: 0.6;
  }

  .ant-modal-header {
    padding: 24px !important;
    border-bottom: none !important;
  }

  .ant-modal-close {
    top: 24px !important;
  }

  .ant-modal-body {
    padding: 8px 24px 22px;
    max-height: 70vh;
    overflow: auto;
  }

  .ant-form-item {
    margin-bottom: 16px !important;

    &.m__b--0 {
      margin-bottom: 0 !important;
    }
  }
}
