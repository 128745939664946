// Text Color
.text {
  font-weight: $regular;
  font-size: $small;
  line-height: 20px;
  color: $text;

  &--regular10.ant-typography {
    font-weight: $regular !important;
    font-size: $xx-small !important;
    line-height: 16px;
  }
  &--regular12.ant-typography {
    font-weight: $regular !important;
    font-size: $x-small !important;
    line-height: 16px;
  }
  &--regular14.ant-typography {
    font-weight: $regular !important;
    font-size: $small !important;
    line-height: 20px;
  }
  &--regular15.ant-typography {
    font-weight: $regular !important;
    font-size: $xl-small !important;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
  &--regular16.ant-typography {
    font-weight: $regular !important;
    font-size: $medium1 !important;
    line-height: 22px;
  }
  &--regular18.ant-typography {
    font-weight: $regular !important;
    font-size: $medium2 !important;
    line-height: 28px;
  }
  &--regular20.ant-typography {
    font-weight: $regular !important;
    font-size: 20px !important;
    line-height: 28px;
  }
  &--regular22.ant-typography {
    font-weight: $regular !important;
    font-size: 22px !important;
    line-height: 32px;
  }
  &--regular24.ant-typography {
    font-weight: $regular !important;
    font-size: 24px !important;
    line-height: 32px;
  }
  &--regular28.ant-typography {
    font-weight: $regular !important;
    font-size: $normal !important;
    line-height: 36px;
  }
  &--medium12.ant-typography {
    font-weight: $medium !important;
    font-size: $x-small !important;
    line-height: 20px;
  }
  &--medium14.ant-typography {
    font-weight: $medium !important;
    font-size: $small !important;
    line-height: 22px;
  }
  &--medium16.ant-typography {
    font-weight: $medium !important;
    font-size: $medium1 !important;
    line-height: 24px;
  }
  &--medium18.ant-typography {
    font-weight: $medium !important;
    font-size: $medium2 !important;
    line-height: 24px;
  }
  &--medium20.ant-typography {
    font-weight: $medium !important;
    font-size: 20px !important;
    line-height: 28px;
  }
  &--medium22.ant-typography {
    font-weight: $medium !important;
    font-size: 22px !important;
    line-height: 32px;
  }
  &--medium24.ant-typography {
    font-weight: $medium !important;
    font-size: 24px !important;
    line-height: 32px;
  }
  &--medium28.ant-typography {
    font-weight: $medium !important;
    font-size: 28px !important;
    line-height: 36px;
  }
  &--medium30.ant-typography {
    font-weight: $medium !important;
    font-size: 30px !important;
    line-height: 36px;
  }
  &--semibold12.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $x-small !important;
    line-height: 16px;
  }
  &--semibold14.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $small !important;
    line-height: 22px;
  }
  &--semibold16.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $medium1 !important;
    line-height: 24px;
  }
  &--semibold18.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $medium2 !important;
    line-height: 28px;
  }
  &--semibold20.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $normal !important;
    line-height: 32px;
  }
  &--semibold24.ant-typography {
    font-weight: $semi-bold !important;
    font-size: $normal2 !important;
    line-height: 32px;
  }
  &--bold12.ant-typography {
    font-weight: $bold !important;
    font-size: 12px !important;
    line-height: 20px;
  }
  &--bold14.ant-typography {
    font-weight: $bold !important;
    font-size: 14px !important;
    line-height: 22px;
  }
  &--bold16.ant-typography {
    font-weight: $bold !important;
    font-size: $medium1 !important;
    line-height: 24px;
  }
  &--bold18.ant-typography {
    font-weight: $bold !important;
    font-size: $medium2 !important;
    line-height: 24px;
  }
  &--bold20.ant-typography {
    font-weight: $bold !important;
    font-size: 20px !important;
    line-height: 28px;
  }
  &--bold22.ant-typography {
    font-weight: $bold !important;
    font-size: 22px !important;
    line-height: 32px;
  }
  &--bold24.ant-typography {
    font-weight: $bold !important;
    font-size: $normal2 !important;
    line-height: 32px;
  }
  &--bold28.ant-typography {
    font-weight: $bold !important;
    font-size: 28px !important;
    line-height: 36px;
  }
  &--bold30.ant-typography {
    font-weight: $bold !important;
    font-size: 30px !important;
    line-height: 36px;
  }
  &--bold32.ant-typography {
    font-weight: $bold !important;
    font-size: $large !important;
    line-height: 40px;
  }
  &--bold36.ant-typography {
    font-weight: $bold !important;
    font-size: $larger !important;
    line-height: 47px;
  }
  &--bold40.ant-typography {
    font-weight: $bold !important;
    font-size: $x-large !important;
    line-height: 40px;
  }
  &--bold48.ant-typography {
    font-weight: $bold !important;
    font-size: 48px !important;
    line-height: 48px;
  }
  &--bold56.ant-typography {
    font-weight: $bold !important;
    font-size: $xx-large !important;
    line-height: 84px;
  }
  &--bold72.ant-typography {
    font-weight: $bold !important;
    font-size: $xxl-large !important;
    line-height: 108px;
  }

  &--primary {
    color: $primary !important;

    &.ant-typography {
      color: $primary !important;
    }

    path {
      stroke: $primary !important;
    }
  }

  &--yellow {
    color: $yellow !important;

    &.ant-typography {
      color: $yellow !important;
    }

    path {
      stroke: $yellow !important;
    }
  }

  &--blue {
    color: $blue !important;

    &.ant-typography {
      color: $blue !important;
    }

    path {
      stroke: $blue !important;
    }
  }
  &--orange {
    color: $orange !important;

    &.ant-typography {
      color: $orange !important;
    }

    path {
      stroke: $orange !important;
    }
  }

  &--white {
    color: $white !important;

    &.ant-typography {
      color: $white !important;
    }

    path {
      stroke: $white !important;
    }
  }

  &--default-50 {
    color: rgba($default, 0.5) !important;

    &.ant-typography {
      color: rgba($default, 0.5) !important;
    }

    path {
      stroke: rgba($default, 0.5) !important;
    }
  }

  &--warning {
    color: $warning !important;

    &.ant-typography {
      color: $warning !important;
    }

    path {
      stroke: $warning !important;
    }
  }

  &--success {
    color: $success !important;

    &.ant-typography {
      color: $success !important;
    }

    path {
      stroke: $success !important;
    }
  }

  &--red {
    color: $red !important;

    &.ant-typography {
      color: $red !important;
    }

    path {
      stroke: $red !important;
    }
  }

  &--green {
    color: $green !important;

    &.ant-typography {
      color: $green !important;
    }

    path {
      stroke: $green !important;
    }
  }

  &--tundola {
    color: $tundola !important;

    &.ant-typography {
      color: $tundola !important;
    }

    path {
      stroke: $tundola !important;
    }
  }

  &--darkGray {
    color: $darkGray !important;

    &.ant-typography {
      color: $darkGray !important;
    }

    path {
      stroke: $darkGray !important;
    }
  }

  &--underline {
    text-decoration: underline;
    span {
      text-decoration: underline;
    }
  }

  &--gray4,
  &--grey4 {
    color: $gray4 !important;

    &.ant-typography {
      color: $gray4 !important;
    }

    path {
      stroke: $gray4 !important;
    }
  }

  &--black {
    color: $black !important;

    &.ant-typography {
      color: $black !important;
    }

    path {
      stroke: $black !important;
    }
  }
  &--default {
    color: $default !important;

    &.ant-typography {
      color: $default !important;
    }

    path {
      stroke: $default !important;
    }
  }
  &--mineShaft {
    color: $mineShaft !important;

    &.ant-typography {
      color: $mineShaft !important;
    }

    path {
      stroke: $mineShaft !important;
    }
  }
  &--error {
    color: $error !important;

    &.ant-typography {
      color: $error !important;
    }

    path {
      stroke: $error !important;
    }
  }
  &--dustyGray {
    color: $dusty_gray !important;

    &.ant-typography {
      color: $dusty_gray !important;
    }
  }

  &--default80 {
    opacity: 0.8;
  }
}

.font__size {
  &--xsmall {
    font-size: $x-small !important;
  }

  &--small {
    font-size: $small !important;
  }

  &--medium {
    font-size: $medium1 !important;
  }

  &--medium2 {
    font-size: $medium2 !important;
  }

  &--normal {
    font-size: $normal !important;
  }

  &--normal2 {
    font-size: $normal2 !important;
  }

  &--large {
    font-size: $large !important;
  }

  &--xlarge {
    font-size: $x-large !important;
  }
  &--xxlarge {
    font-size: $xx-large;
  }
}

// Font Weight
.font__weight {
  &--regular {
    font-weight: $regular !important;
  }
  &--medium {
    font-weight: $medium !important;
  }
  &--semi-bold {
    font-weight: $semi-bold !important;
  }
  &--bold {
    font-weight: $bold !important;
  }
  &--extra-bold {
    font-weight: $extra-bold !important;
  }
}

// Font Opacity
.font__opacity {
  &--regular {
    color: rgba($default, 0.7);
  }
  &--medium {
    color: rgba($default, 0.5);
  }

  &--regular-gray4 {
    color: rgba($gray4, 0.7);
  }
  &--medium-gray4 {
    color: rgba($gray4, 0.5);
  }
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.text__align {
  &--left {
    text-align: left !important;
  }

  &--center {
    text-align: center !important;
  }

  &--right {
    text-align: right !important;
  }
}

.ant-typography {
  strong {
    font-weight: 500;
  }
}

h1,
h2,
h3,
h4 {
  &.ant-typography {
    font-weight: 500;
  }
}

.text__transform {
  &--lowercase {
    text-transform: lowercase;
  }
  &--uppercase {
    text-transform: uppercase;
  }
}
h1.ant-typography,
.ant-typography h1,
div.ant-typography,
.ant-typography p {
  margin-bottom: 0;
}
.leading {
  &-16 {
    line-height: 16px !important;
  }
  &-20 {
    line-height: 20px !important;
  }
  &-22 {
    line-height: 22px !important;
  }
  &-24 {
    line-height: 24px !important;
  }
  &-28 {
    line-height: 28px !important;
  }
  &-42 {
    line-height: 42px !important;
  }
}

.letter__spacing {
  &-05 {
    letter-spacing: -0.5px;
  }
  &-02 {
    letter-spacing: -0.2px;
  }
  &--05 {
    letter-spacing: 0.5px;
  }
  &--1 {
    letter-spacing: 1px;
  }
  &--2 {
    letter-spacing: 2px;
  }
  &--3 {
    letter-spacing: 3px;
  }
}

.fill {
  &--dustyGray {
    path {
      fill: $dusty_gray !important;
    }
  }
}
