.ant-collapse {
  background-color: $white;
  border: none;
  position: relative;
  max-width: calc(100vw - 32px);
  width: 100%;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0 !important;
}

.pannel-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.pannel-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  cursor: context-menu !important;
}
