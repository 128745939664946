@import "../../styles/abstracts/variables";

.experience-layout {
  height: 100vh;
  overflow: hidden;

  &__header.ant-layout-header {
    padding: 0 16px;
    background-color: $white;
    height: 80px;
    box-shadow: 0 1px 0 $border;
    margin-bottom: 1px;

    @include mobile {
      height: 64px;
      padding: 0 16px;
    }
  }

  &__content {
    background-color: $white;
    height: calc(100vh - 80px);
    overflow: auto;
    overflow-x: hidden;
  }

  .ant-layout-footer {
    padding: 0;
    background: $white;
  }

  &--profile {
    -webkit-transform: translate3d(0, 0, 0);
  }

  &--livestream {
    .experience-layout__header {
      padding: 0 48px;
      background: $codGray;
      margin-bottom: 0;

      .ant-dropdown-trigger {
        padding-right: 0;
      }
    }

    .experience-layout__content {
      margin-left: 0;
      background: $codGray;

      .container {
        width: 100% !important;
        max-width: 100% !important;
      }
    }

    .btn-create-experience {
      display: none;
    }

    &--waiting {
      .experience-layout__header {
        background: $white;
        margin-bottom: 1px;
      }

      .experience-layout__content {
        background: $white;

        .live-stream-app {
          height: calc(100vh - 81px);
        }
      }
    }
  }

  &--experience {
    height: calc(100vh - 76px);

    .experience-layout__content {
      margin-left: 232px;

      .ant-layout-content {
        overflow-x: visible;
      }
    }
  }

  &--full {
    .experience-layout__content {
      margin-left: 0;
    }
  }

  &--noheader {
    .ant-layout-sider {
      top: 0 !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .experience-layout {
    height: 100vh;

    .experience-layout__content {
      margin-left: 0;
      padding-bottom: 100px;
    }

    .container {
      max-width: 100% !important;

      aside.ant-layout-sider.mobile-sider {
        box-shadow: $boxShadow;
      }
    }
  }
}
