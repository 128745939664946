@import "../../styles/abstracts/variables";
.publish-modal {
  .ant-modal-content {
    .ant-modal-header {
      padding: 24px 24px 8px 24px !important;
      border-bottom: none;
    }
    .ant-modal-body {
      padding: 0 24px 24px 24px !important;
      button {
        width: 100%;
        height: 50px;
      }
    }
  }
  &__content {
    border: 1px solid $border2;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: auto;
    .ant-checkbox {
      width: 24px;
      height: 24px;
      &.ant-checkbox-checked .ant-checkbox-inner {
        background: $lightGreen;
        border-color: $lightGreen !important;

        &::after {
          width: 6px;
          height: 10px;
          left: 30%;
          top: 45%;
        }
      }
      &:hover,
      &:active,
      &:focus {
        .ant-checkbox-inner {
          border-color: $lightGreen !important;
        }
      }
      + span {
        padding: 0 8px;
      }

      &-checked::after {
        visibility: hidden !important;
      }

      input {
        width: 24px;
        height: 24px;
        padding: 0;
      }

      &-inner {
        width: 20px;
        height: 20px;
        margin: auto;
        top: 2px;
      }
    }
  }
  &__warning {
    background: $gray12;
    padding: 8px 16px;
    border-radius: 8px;

    .ant-typography {
      flex: none;
      order: 0;
      flex-grow: 0;
      letter-spacing: -0.2px;
    }
  }
}
