.waiting-room-setup {
  max-width: 762px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 16px;
  &__dropdown {
    background: $default !important;
    .ant-select-item {
      background: $default !important;
      color: $white !important;
    }
  }
  &__devices {
    &--select {
      border-radius: 8px;
      .ant-select-selector,
      .ant-select-selection-item {
        background-color: $default !important;
        color: $white !important;
        border: none !important;
        font-size: $medium1 !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
      }

      .ant-select-selection-item {
        border-radius: 8px !important;
      }

      .ant-select-selector {
        padding: 0 !important;
      }

      .ant-select-arrow {
        width: 24px !important;
        margin-right: 16px !important;

        svg path {
          stroke: $white !important;
        }
      }
    }

    &--select--option {
      display: flex;
      align-items: center;

      > svg {
        margin-right: 10px;
      }
    }
  }

  &__header {
    height: 101px;
    padding: 0 24px;
    width: 100%;
    border-radius: 16px;
    background:
      linear-gradient(
        270deg,
        #17e391 21.47%,
        #58e5df 77.04%,
        #60e5e8 77.05%
      );

    * {
      color: $default !important;
    }

    @include mobile {
      height: 82px;
      padding: 0 16px;
    }
  }
  &__first-setup {
    background: $mineShaft;
    border-radius: 16px;
    padding: 40px;
    width: 100%;
    margin-top: 24px;
    &--sub-title {
      max-width: 486px;
    }
    &--button {
      width: 100%;
      max-width: 255px;

      @include mobile {
        max-width: 100%;
      }
    }

    @include mobile {
      padding: 40px 16px;
      margin-top: 20px;
    }
  }
  &__permission-steps {
    background: $mineShaft;
    border-radius: 16px;
    width: 100%;
    margin-top: 24px;
    &__header {
      padding: 24px 40px;
      border-bottom: 1px solid rgba($white, 0.1);

      @include mobile {
        padding: 16px 0;
      }
      .ant-steps-item-icon {
        margin-right: 0;
      }
      .ant-steps-item {
        flex: unset;
      }
      .ant-steps-icon {
        display: flex;
        align-items: center;
      }
      .ant-steps-label-vertical .ant-steps-item-content {
        margin-top: 0;
      }
      .ant-steps-label-vertical {
        overflow-x: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .ant-steps-item-tail {
        &::after {
          background-color: transparent !important;
        }
      }
      .ant-steps-item-title {
        font-size: $small;
        font-weight: $medium;
        text-transform: uppercase;
        color: $white !important;
        letter-spacing: 2px;

        @include mobile {
          font-size: $x-small;
        }
        &::after {
          opacity: 0.2;
          background-color: $white !important;
        }
      }
      .ant-steps-item-wait {
        .ant-steps-item-title,
        .ant-steps-icon {
          opacity: 0.5;
        }
      }
    }
    &__content {
      padding: 24px 40px 35px 40px;

      @include mobile {
        padding: 24px 16px 24px 16px;
      }
    }
  }
  .camera-permission {
    &__camera {
      border-radius: 16px;
      width: 100%;
      margin: auto;
      position: relative;
      &--button {
        position: absolute;
        bottom: 8px;
        width: 40px;
        height: 40px;
        background: rgba(#292929, $alpha: 0.8);
        justify-content: center;
        align-items: center;
        display: flex;
        border: none;
        padding: 0;
        svg {
          width: 24px;
          height: 24px;
        }
      }

      @include mobile {
        max-width: 56vw;
        video {
          width: 211px;
          max-height: 295px;
          border-radius: 16px;
        }
      }
    }
    &__empty-select {
      background: #121212;
      border-radius: 8px 8px 0 0;
      margin-top: 24px;
      padding: 14px 16px;
    }
    &__empty-camera {
      width: 100%;
      height: 367px;
      background: $black;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include mobile {
        width: 211px;
        height: 295px;
      }
    }
  }
  .warning-content {
    background: rgba(255, 60, 69, 0.1);
    padding: 10px;
    width: 100%;
  }
}
