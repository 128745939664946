@import "../../styles/abstracts/variables";

.not-support-size-browser {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @include mobile {
    padding: 36px;
  }
  &__container {
    width: 100%;
    max-width: 548px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
